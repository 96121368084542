import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { DefaultLayout } from "../layouts";
import { white, maxWidth, red } from "../utils";
import ReactMarkdownWithHtml from "react-markdown/with-html";

const Background = styled.section`
  width: 100%;
  background-image: url(${props => props.image});
  background-position: center;
  background-size: cover;
  backgroud-repeat: no-repeat;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 10px;
  max-width: ${maxWidth};
`;

const TextWrapper = styled.div`
  width: 100%;
  white-space: pre-wrap;
  border-top: solid 1px ${white};
  border-bottom: solid 1px ${white};
  background-color: rgb(89,89,89, 0.75);
  max-width: 850px;
`;
const P = styled.p`
  padding: 20px 10px;
  > * {
    color: white;
    max-width: 750px;
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 10px;
    & a {
      color: ${red};
    }
  }
`;

const HomePage = ({ data }) => (
  <DefaultLayout title="Home">
    <Background image={data.home.image}>
      <Wrapper>
        <TextWrapper>
          <P><ReactMarkdownWithHtml children={data.home.content} allowDangerousHtml /></P>
        </TextWrapper>
      </Wrapper>
    </Background>
  </DefaultLayout>
);

export const query = graphql`
  {
    home: homeJson {
      image
      content
    }
  }
`;

export default HomePage;
